import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

import styles from './form-field.module.scss';

function numbersOnly(event) {
  if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].includes(event.key)) {
    event.preventDefault();
  }
}

function SingleFieldWrapper({ id, label, isInvalid, error, children, className }) {
  return (
    <div className={classnames(styles.field, className)}>
      <label className={styles.field__label} htmlFor={id}>
        {label}
      </label>
      {children}
      {isInvalid && (
        <span className={styles.field__error} role="alert">
          {error}
        </span>
      )}
    </div>
  );
}
function GroupFieldWrapper({ id, label, isInvalid, error, children, className }) {
  return (
    <div className={classnames(styles.field, className)}>
      {label && (
        <span className={styles.field__label} id={`${id}__group-label`}>
          {label}
        </span>
      )}
      <div role="group" aria-labelledby={label ? `${id}__group-label` : null}>
        {children}
      </div>
      {isInvalid && (
        <span className={styles.field__error} role="alert">
          {error}
        </span>
      )}
    </div>
  );
}

export default function FormField({
  type,
  name,
  id,
  label,
  placeholder,
  options = [],
  min,
  max,
  disabled = false,
  className,
  format = 'native',
  onChange,
  ...rest
}) {
  const [field, meta] = useField({ name });
  const isInvalid = meta.error && meta.touched;

  const wrapperProps = {
    id,
    label,
    isInvalid,
    meta,
    error: meta.error,
    className
  };

  function handleChange(event) {
    field.onChange(event);
    if (onChange) {
      onChange(event);
    }
  }

  switch (type) {
    case 'radios':
      return (
        <GroupFieldWrapper {...wrapperProps}>
          {options.map(({ label, value }, index) => {
            const checked = value.toString() === field?.value?.toString();
            return (
              <label
                className={classnames(
                  styles.field__radio,
                  styles[`field__radio--${format}`],
                  checked && styles['field__radio--checked']
                )}
                key={index}
              >
                {label}
                <input
                  {...field}
                  type="radio"
                  id={`${id}--${value}`}
                  value={value}
                  checked={checked}
                  disabled={disabled}
                  onChange={handleChange}
                  {...rest}
                />
              </label>
            );
          })}
        </GroupFieldWrapper>
      );
    case 'checkbox':
      const checked = field.value.toString() === 'true';
      return (
        <SingleFieldWrapper {...{ ...wrapperProps, label: null }}>
          <label
            className={classnames(
              styles.field__checkbox,
              checked && styles['field__checkbox--checked']
            )}
          >
            {label}
            <input
              {...field}
              type="checkbox"
              id={id}
              checked={checked}
              disabled={disabled}
              onChange={handleChange}
              {...rest}
            />
          </label>
        </SingleFieldWrapper>
      );
    case 'checkboxes':
      return (
        <GroupFieldWrapper {...wrapperProps}>
          {options.map(({ label, value }, index) => {
            const checked = field.value.includes(value);
            return (
              <label
                className={classnames(
                  styles.field__checkbox,
                  checked && styles['field__checkbox--checked']
                )}
                key={index}
              >
                {label}
                <input
                  {...field}
                  type="checkbox"
                  id={`${id}--${value}`}
                  value={value}
                  checked={checked}
                  disabled={disabled}
                  onChange={handleChange}
                  {...rest}
                />
              </label>
            );
          })}
        </GroupFieldWrapper>
      );

    case 'select':
      return (
        <SingleFieldWrapper {...wrapperProps}>
          <select
            {...field}
            className={classnames(
              styles.field__dropdown,
              isInvalid && styles['field__dropdown--invalid']
            )}
            id={id}
            disabled={disabled}
            onChange={handleChange}
            {...rest}
          >
            <option value="" disabled>
              {placeholder || 'Please select:'}
            </option>
            {options.map(({ label, value }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>
        </SingleFieldWrapper>
      );

    case 'textarea':
      return (
        <SingleFieldWrapper {...wrapperProps}>
          <textarea
            {...field}
            className={classnames(
              styles.field__textarea,
              isInvalid && styles['field__textarea--invalid']
            )}
            id={id}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
          />
        </SingleFieldWrapper>
      );

    case 'number':
      return (
        <SingleFieldWrapper {...wrapperProps}>
          <input
            {...field}
            className={classnames(
              styles.field__input,
              isInvalid && styles['field__input--invalid']
            )}
            id={id}
            type="text"
            inputMode="decimal"
            pattern="[0-9.]*"
            onKeyPress={numbersOnly}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
          />
        </SingleFieldWrapper>
      );
    case 'date':
      return (
        <SingleFieldWrapper {...wrapperProps}>
          <DatePicker
            name={field.name}
            id={id}
            className={classnames(
              styles.field__input,
              isInvalid && styles['field__input--invalid']
            )}
            dateFormat="dd/MM/yyyy"
            selected={field.value ? new Date(field.value) : ''}
            onChange={(value, event) => {
              field.onChange({
                target: { name: field.name, id, value: value || '' }
              });
              if (onChange) {
                onChange(event);
              }
            }}
            onBlur={field.onBlur}
            minDate={min}
            maxDate={max}
            {...rest}
          />
        </SingleFieldWrapper>
      );

    default:
      return (
        <SingleFieldWrapper {...wrapperProps}>
          <input
            {...field}
            className={classnames(
              styles.field__input,
              isInvalid && styles['field__input--invalid']
            )}
            id={id}
            type={type}
            min={min}
            max={max}
            disabled={disabled}
            onChange={handleChange}
            placeholder={placeholder}
            {...rest}
          />
        </SingleFieldWrapper>
      );
  }
}
