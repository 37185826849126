import api from '../utils/api';

export function fetchAllUsers(query) {
  return api.get('/users', { params: query });
}

export function fetchUserById(id) {
  return api.get(`/users/${id}`);
}

export function createUser(user) {
  return api.post(`/users`, user);
}

export function updateUser(id, updates) {
  return api.put(`/users/${id}`, updates);
}

export function changeUserPassword(id, data) {
  return api.put(`/users/${id}/password`, data);
}
