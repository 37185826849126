import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useFlash from '../hooks/useFlash';

export default function PrivateRoute({ children, permissions, ...props }) {
  const { isAuthenticated, auth } = useAuth();
  const { redirectWithFlash } = useFlash();

  //if (!isAuthenticated) return <Navigate to={`/login`} />;
  if (permissions && auth.user.permissions[permissions].toString() !== 'true') {
    redirectWithFlash('/', 'You do not have the required permissions to access that page', 'error');
  }

  // return <Route {...props}>{children}</Route>;
  return isAuthenticated ? <Outlet /> : <Navigate to={`/login`} />
}
