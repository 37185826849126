import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import Button from '../button/button';
import { Col, Grid } from '../grid/grid';

export default function Pagination({ currentPage, lastPage, from, to, total }) {
  const { pathname, search } = useLocation();
  const query = querystring.parse(search);
  const parsedCurrentPage = Number(currentPage);
  const parsedLastPage = Number(lastPage);

  return (
    <Grid valign="center">
      <Col className="range-left">
        {currentPage > 1 && (
          <Button
            format="primary--o"
            href={`${pathname}?${querystring.stringify({
              ...query,
              page: parsedCurrentPage - 1
            })}`}
          >
            Previous
          </Button>
        )}
      </Col>
      <Col className="range-center">
        <span>
          {from + 1}-{to} of {total}
        </span>
      </Col>
      <Col className="range-right">
        {parsedCurrentPage !== parsedLastPage && (
          <Button
            format="primary--o"
            href={`${pathname}?${querystring.stringify({
              ...query,
              page: parsedCurrentPage + 1
            })}`}
          >
            Next
          </Button>
        )}
      </Col>
    </Grid>
  );
}
