import * as Yup from 'yup';
import { VALIDATION_REQUIRED } from '../../constants';

const schema = Yup.object().shape({
  // delivery_location: Yup.string().required(VALIDATION_REQUIRED),
  delivery_date: Yup.string().required(VALIDATION_REQUIRED),
  po_number: Yup.string().required(VALIDATION_REQUIRED)
});

export default schema;
