import * as Yup from 'yup';
import { VALIDATION_REQUIRED, VALIDATION_PASSWORD_MATCH } from '../../constants';

export default Yup.object().shape({
  password: Yup.string().required(VALIDATION_REQUIRED),
  passwordConfirmation: Yup.string()
    .required(VALIDATION_REQUIRED)
    .test('passwords-match', VALIDATION_PASSWORD_MATCH, function (value) {
      return this.parent.password === value;
    })
});
