import { NavLink, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import querystring from 'query-string';
import Container from '../../components/container/container';
import SearchForm from '../../forms/search-form/search-form';
import ErrorMessage from '../../components/error-message/error-message';
import Table from '../../components/table/table';
import Pagination from '../../components/pagination/pagination';
// import ProductInfo from '../../components/product-info/product-info';
import AddToCart from '../../components/add-to-cart/add-to-cart';

import { money } from '../../utils/string';
import { fetchAllProducts } from '../../services/products';
import useService from '../../hooks/useService';

import styles from './products.module.scss';

export default function ProductListPage({ filter = null }) {
  const { auth } = useAuth();
  const location = useLocation();

  // Set the API query based on the current filter and any other params
  let query = querystring.parse(location.search);
  switch (filter) {
    case 'specials':
      query.isSpecial = 1;
      break;
    case 'popular':
      query.filter = 'commonOrders';
      break;
    case 'top-50':
      query.filter = 'top50';
      break;
    case 'previously-ordered':
      query.filter = 'hasOrdered';
      break;
    default:
      break;
  }

  const { error, loading, response } = useService(fetchAllProducts, query);

  return (
    <main>
      <Container>
        <h1 className="brand tight">{filter === 'specials' ? 'Special Offers' : 'Products'}</h1>

        {!auth.user.isStaff && filter !== 'specials' && (
          <nav className={styles.filters}>
            <NavLink className={styles.filters__link} to="/products" exact="true">
              All
            </NavLink>
            <NavLink className={styles.filters__link} to="/products/popular" exact="true">
              Popular
            </NavLink>
            <NavLink className={styles.filters__link} to="/products/top-50" exact="true">
              My Usuals
            </NavLink>
            <NavLink className={styles.filters__link} to="/products/previously-ordered" exact="true">
              Previously Ordered
            </NavLink>
          </nav>
        )}

        <SearchForm />

        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {response && response.data && response.data.length > 0 && (
          <>
            <Table
              className={styles.products}
              columns={[
                {
                  label: 'Name',
                  key: 'name',
                  sortable: true
                },
                // {
                //   label: 'Weight',
                //   key: 'weight',
                //   className: styles.product__weight
                // },
                {
                  label: 'Unit',
                  key: 'unit',
                  className: styles.product__unit
                },
                {
                  label: 'Price',
                  key: 'price',
                  getValue: (p) => money(p.price),
                  sortable: true,
                  className: styles.product__price
                },
                {
                  label: ' ',
                  key: 'actions',
                  className: styles.product__actions,
                  getValue: (p) => <AddToCart product={p} />
                }
              ]}
              data={response.data}
            />
            <Pagination {...response.pagination} />
          </>
        )}
        {response && response.data.length === 0 && (
          <p className="heavy">No products match your query.</p>
        )}
      </Container>
    </main>
  );
}
