import Container from '../../components/container/container';
import CartBreadcrumbs from '../../components/cart-breadcrumbs/cart-breadcrumbs';
import Button from '../../components/button/button';
import useAuth from '../../hooks/useAuth';
import useFlash from '../../hooks/useFlash';
import useService from '../../hooks/useService';
import { fetchCart, fetchCartSettings } from '../../services/cart';
import { placeOrder } from '../../services/orders';
import { money } from '../../utils/string';
import { displayDate } from '../../utils/dates';

import styles from './cart.module.scss';

export default function CartConfirmPage() {
  // const history = useNavigate();
  const { auth } = useAuth();
  const { showFlash, redirectWithFlash } = useFlash();
  const { error: cartError, loading: cartLoading, response: cartItems } = useService(fetchCart);
  const {
    error: settingsError,
    loading: settingsLoading,
    response: cartSettings
  } = useService(fetchCartSettings);

  async function onConfirm() {
    try {
      await placeOrder();
      redirectWithFlash('/', 'Order placed!', 'success');
    } catch (error) {
      //console.log(error.response);
      showFlash(error?.response?.data || error.message, 'error');
    }
  }

  return (
    <main>
      <Container>
        <CartBreadcrumbs />
        <h1 className="brand">Confirm Order</h1>

        {(cartLoading || settingsLoading) && <p>Loading...</p>}

        {cartError && <pre>{cartError.message}</pre>}
        {settingsError && <pre>{settingsError.message}</pre>}

        {cartItems && cartSettings && (
          <>
            <dl className={styles.cart__confirmation__orderDetails}>
              <dt>PO number:</dt>
              <dl>{cartSettings.po_number}</dl>
              {/* <dt>Delivery location:</dt>
              <dl>{cartSettings.delivery_location}</dl> */}
              <dt>{auth.user.isStaff ? 'Delivery date' : 'Requested delivery date'}:</dt>
              <dl>{displayDate(cartSettings.delivery_date)}</dl>
              <dt>Delivery notes:</dt>
              <dl>{cartSettings.delivery_note}</dl>
            </dl>
            <table className={styles.cart__confirmation__table}>
              <thead className="brand">
                <tr>
                  <th>Product</th>
                  {/* <th className={styles.cart__confirmation__table__note}>Notes</th> */}
                  <th className={styles.cart__confirmation__table__quantity}>Qty</th>
                  <th className={styles.cart__confirmation__table__unit}>Unit</th>
                  <th className={styles.cart__confirmation__table__total}>Total</th>
                </tr>
              </thead>

              {cartItems.map((cartItem, index) => (
                <tbody className={styles.cart__confirmation__table__item} key={cartItem.id}>
                  <tr>
                    <td>
                      <h5 className="heavy flush primary">{cartItem.name}</h5>
                      <p>
                        {money(cartItem.price)} {cartItem.unit ? `(${cartItem.unit})` : null}
                      </p>
                    </td>
                    <td>{cartItem.quantity}</td>
                    <td>{cartItem.unit}</td>
                    <td>{money(cartItem.price * cartItem.quantity)}</td>
                  </tr>
                </tbody>
              ))}

              <tfoot>
                <tr>
                  <td colSpan="3"></td>
                  <td className={`${styles.cart__confirmation__table__total} lg`}>
                    <span className="brand">TOTAL:</span>
                    <br />
                    <strong>
                      {money(
                        cartItems.reduce(function (val, item) {
                          return val + item.price * item.quantity;
                        }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            <div className="range-right">
              <Button outlined href="/cart/order-details">
                &laquo; Back
              </Button>
              <Button onClick={onConfirm}>Place Order</Button>
            </div>
            <p className="heavy range-center">
            {!auth.user.isStaff ? 'Orders with a daily value of less than £100 won\'t be processed' : ''}
            </p>
          </>
        )}
      </Container>
    </main>
  );
}
