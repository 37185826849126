import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import useFlash from '../../hooks/useFlash';
import { updateAccount } from '../../services/accounts';

export default function AccountOnHoldForm({ id, onHold }) {
  const { showFlash } = useFlash();

  async function handleSubmit(values, formik) {
    try {
      await updateAccount(id, values);
      formik.setSubmitting(false);
    } catch (error) {
      console.error(error);
      showFlash(error.message, 'error');
    }
  }
  return (
    <Formik initialValues={{ onHold }} onSubmit={handleSubmit} enableReinitialize>
      {({ isSubmitting, submitForm }) => (
        <Form>
          <FormField
            type="radios"
            name="onHold"
            id="onHold"
            disabled={isSubmitting}
            format="toggle"
            options={[
              { label: 'Yes', value: '1' },
              { label: 'No', value: '0' }
            ]}
            onChange={submitForm}
          />
        </Form>
      )}
    </Formik>
  );
}
