import api from '../utils/api';

export function fetchLocations() {
  return api.get('/locations');
}

export function fetchLocationByID(id) {
  return api.get(`/locations/${id}`);
}

export function fetchLocationsByAccount(accountId) {
  return api.get(`/locations/account/${accountId}`);
}

export function updateLocation(id, updates) {
  return api.put(`/locations/${id}`, updates);
}
