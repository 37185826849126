const initialValues = {
  id: '',
  delivery_config: {
    delivery_days: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    },
    delivery_cutoffs: {
      monday: '19:00',
      tuesday: '19:00',
      wednesday: '19:00',
      thursday: '19:00',
      friday: '19:00',
      saturday: '19:00',
      sunday: '19:00'
    }
  }
};

export default initialValues;
