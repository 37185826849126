import * as Yup from 'yup';

const schema = Yup.object().shape({
  id: Yup.string().required('Required'),
  delivery_config: Yup.object().shape({
    delivery_days: Yup.object().shape({
      monday: Yup.boolean().required('Required'),
      tuesday: Yup.boolean().required('Required'),
      wednesday: Yup.boolean().required('Required'),
      thursday: Yup.boolean().required('Required'),
      friday: Yup.boolean().required('Required'),
      saturday: Yup.boolean().required('Required'),
      sunday: Yup.boolean().required('Required')
    }),
    delivery_cutoffs: Yup.object().shape({
      monday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      tuesday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      wednesday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      thursday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      friday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      saturday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM'),
      sunday: Yup.string()
        .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'HH:MM')
        .required('HH:MM')
    })
  })
});

export default schema;
