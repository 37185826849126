import Icon from '../icon/icon';

import styles from './modal.module.scss';

export default function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modal} role="dialog" aria-modal="true">
      <div className={styles.modal__content}>
        <button type="button" aria-label="Close" className={styles.modal__close} onClick={onClose}>
          <Icon>close</Icon>
        </button>
        <div className={styles.modal__content__inner}>{children}</div>
      </div>
    </div>
  );
}
