import api from '../utils/api';

export function fetchAllOrders(query) {
  return api.get('/orders', { params: query });
}
export function fetchOrder(id) {
  return api.get(`/orders/${id}`);
}
export function fetchHistory(query) {
  return api.get('/orders/history', { params: query });
}
export function fetchEventLog(id) {
  return api.get(`/orders/${id}/events`);
}

export function placeOrder() {
  return api.post('/orders');
}

export function requestOrderInvoice(order_id) {
  return api.get('/soap/requestEmailInvoice', { params: { order_id } });
}

export function resendOrderToNucleus(order_id) {
  return api.get('/soap/sendOrderRequest', { params: { order_id } });
}
