import { useState } from 'react';
import { Formik, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import Container from '../../components/container/container';
import ClosureMessage from '../../components/closure-message/closure-message';

import Button from '../../components/button/button';
import FormField from '../../components/form-field/form-field';
import initialValues from './initialValues';
import validationSchema from './validationSchema';
import useAuth from '../../hooks/useAuth';

import styles from './login.module.scss';
import SubmissionError from '../../components/submission-error/submission-error';

export default function LoginPage() {
  const history = useNavigate();
  const { login } = useAuth();
  const [authError, setAuthError] = useState(null);

  async function handleSubmit(values) {
    setAuthError(null);
    try {
      await login(values);
      history('/');
    } catch (error) {
      setAuthError(error.response.data);
    }
  }

  return (
    <main>
      <Container>
        <h1 className="range-center brand">Login</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className={styles.form} noValidate>
            <FormField
              type="email"
              name="username"
              id="username"
              label="Email"
            />
            <FormField
              type="password"
              name="password"
              id="password"
              label="Password"
            />
            <p>
              <Link to="/password-reset" className="heavy muted">
                Forgot your password?
              </Link>
            </p>
            <SubmissionError error={authError} />
            <Button type="submit">Log in</Button>
          </Form>
        </Formik>
        <ClosureMessage />
      </Container>
    </main>
  );
}
