import styles from './footer.module.scss';

import Container from '../container/container';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container>
        <small>&copy; {new Date().getFullYear()} Aubrey Allen</small>
      </Container>
    </footer>
  );
}
