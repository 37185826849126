import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/auth-context';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import FlashMessage from './components/flash-message/flash-message';
import PrivateRoute from './components/private-route';
import Home from './pages/home/home';
import LoginPage from './pages/login/login';
import HowtoPage from './pages/howto/howto';
import PasswordResetStartPage from './pages/password-reset/start';
import PasswordResetConfirmPage from './pages/password-reset/change';
import MyAccountPage from './pages/my-account/my-account';
import AccountsListPage from './pages/admin/accounts/accounts';
import AccountsEditPage from './pages/admin/accounts/edit';
import UsersListPage from './pages/admin/users/list';
import UserEditPage from './pages/admin/users/edit';
import AddUserPage from './pages/admin/users/add';
import HistoryListPage from './pages/admin/history/list';
import HistoryDetailsPage from './pages/admin/history/order';
import ProductListPage from './pages/products/products';
import CartPage from './pages/cart/cart';
import CartOrderDetailsPage from './pages/cart/order-details';
import CartConfirmPage from './pages/cart/confirm';
import OrdersListPage from './pages/orders/list';
import OrderDetailsPage from './pages/orders/order';
import NotFoundPage from './pages/not-found';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />

        <FlashMessage />

        <Routes>
          <Route exact="true" path="/login" element={<LoginPage />} />
          <Route exact="true" path="/how-to" element={<HowtoPage />} />
          <Route exact="true" path='/' element={<PrivateRoute/>}>
                <Route exact="true" path='/' element={<Home/>}/>
          </Route>

          <Route exact="true" path='/password-reset' element={<PasswordResetStartPage />}/>
          <Route exact="true" path="/password-reset/:token" element={<PasswordResetConfirmPage />} />

          <Route exact="true" path='/my-account' element={<PrivateRoute/>}>
                <Route exact="true" path='/my-account' element={<MyAccountPage />}/>
          </Route>

          <Route exact="true" path='/products' element={<PrivateRoute/>}>
                <Route exact="true" path='/products' element={<ProductListPage />}/>
          </Route>
          {['specials', 'popular', 'previously-ordered', 'top-50'].map((filter) => (
            <Route exact="true" path={`/products/${filter}`} key={filter} element={<PrivateRoute/>}>
                  <Route exact="true" path={`/products/${filter}`} key={filter} element={<ProductListPage filter={filter} />}/>
            </Route>
          ))}
          <Route exact="true" path='/cart' element={<PrivateRoute/>}>
                <Route exact="true" path='/cart' element={<CartPage />}/>
          </Route>
          <Route exact="true" path='/cart/order-details' element={<PrivateRoute/>}>
                <Route exact="true" path='/cart/order-details' element={<CartOrderDetailsPage />}/>
          </Route>
          <Route exact="true" path='/cart/confirm' element={<PrivateRoute/>}>
                <Route exact="true" path='/cart/confirm' element={<CartConfirmPage />}/>
          </Route>

          <Route exact="true" path='/orders' element={<PrivateRoute/>}>
                <Route exact="true" path='/orders' element={<OrdersListPage />}/>
          </Route>
          <Route exact="true" path='/orders/:id' element={<PrivateRoute/>}>
                <Route exact="true" path='/orders/:id' element={<OrderDetailsPage />}/>
          </Route>

          <Route exact="true" path='/admin/users' permissions="canManageUsers" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/users' permissions="canManageUsers" element={<UsersListPage />}/>
          </Route>
          <Route exact="true" path='/admin/users/add' permissions="canManageUsers" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/users/add' permissions="canManageUsers" element={<AddUserPage />}/>
          </Route>
          <Route exact="true" path='/admin/users/:id' permissions="canManageUsers" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/users/:id' permissions="canManageUsers" element={<UserEditPage />}/>
          </Route>

          <Route exact="true" path='/admin/accounts' permissions="canManageAccounts" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/accounts' permissions="canManageAccounts" element={<AccountsListPage />}/>
          </Route>
          <Route exact="true" path='/admin/accounts/:id' permissions="canManageAccounts" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/accounts/:id' permissions="canManageAccounts" element={<AccountsEditPage />}/>
          </Route>

          <Route exact="true" path='/admin/history' permissions="canViewHistory" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/history' permissions="canViewHistory" element={<HistoryListPage />}/>
          </Route>
          <Route exact="true" path='/admin/history/:id' permissions="canViewHistory" element={<PrivateRoute/>}>
                <Route exact="true" path='/admin/history/:id' permissions="canViewHistory" element={<HistoryDetailsPage />}/>
          </Route>

          <Route element={<NotFoundPage />} />
        </Routes>

        <Footer />
      </Router>
    </AuthProvider>
  );
}
