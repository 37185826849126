import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './button.module.scss';

export default function Button({
  type = 'button',
  onClick,
  format = 'primary',
  outlined = false,
  children,
  href,
  className,
  disabled = false,
  small
}) {
  const sharedProps = {
    onClick,
    className: classnames(
      styles.btn,
      styles[`btn--${format}${outlined ? '--o' : ''}`],
      small && styles['btn--small'],
      className
    ),
    disabled
  };

  if (href) {
    return (
      <Link to={href} {...sharedProps}>
        {children}
      </Link>
    );
  }

  return (
    <button type={type} {...sharedProps}>
      {children}
    </button>
  );
}
