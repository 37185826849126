const initialValues = {
  username: '',
  erp_account_id: '',
  location_default: '',
  permissions: {
    canManageAccounts: '',
    canManageUsers: '',
    canOrder: '',
    canViewCredit: '',
    canViewHistory: '',
    canViewPricing: '',
    canViewInvoicing: ''
  }
};
export default initialValues;
