import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import { money } from '../../utils/string';

import validationSchema from './validationSchema';

import styles from './cart-list-form.module.scss';

export default function CartListingForm({ onSubmit, onItemDelete, cartItems, auth }) {
  return (
    <Formik onSubmit={onSubmit} initialValues={cartItems} validationSchema={validationSchema}>
      {(formik) => (
        <Form>
          {formik.values.length > 0 ? (
            <table className={styles.cart__table}>
              <thead className="brand">
                <tr>
                  <th>Product</th>
                  <th className={styles.cart__table__quantity}>Quantity</th>
                  <th className={styles.cart__table__unit}>Unit</th>
                  <th className={styles.cart__table__remove}>Remove</th>
                  <th className={styles.cart__table__total}>Total</th>
                </tr>
              </thead>

              {formik.values.map((cartItem, index) => (
                <tbody className={styles.cart__table__item} key={cartItem.id}>
                  <tr>
                    <td>
                      <h5 className="heavy flush primary">{cartItem.name}</h5>
                      {cartItem.price ? (
                        <p>
                          {money(cartItem.price)} {cartItem.pUnit ? `(${cartItem.pUnit})` : null}
                        </p>
                      ) : null}
                    </td>
                    <td>
                      <FormField
                        type="number"
                        inputMode="decimal"
                        pattern="[0-9.]*"
                        name={`${index}.quantity`}
                        id="quantity"
                        className={styles.cart__table__item__field}
                      />
                    </td>
                    <td>
                      <FormField
                        type="select"
                        name={`${index}.unit`}
                        id={`${index}.unit`}
                        options={cartItem.orderableUnits.map((unit) => ({
                          label: unit,
                          value: unit
                        }))}
                        disabled={cartItem.orderableUnits.length < 2}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          onItemDelete(cartItem.id, formik);
                        }}
                      >
                        <Icon>delete</Icon>
                      </button>
                    </td>
                    <td>{money(cartItem.price * formik.values[index].quantity)}</td>
                  </tr>
                </tbody>
              ))}

              <tfoot>
                <tr>
                  <td colSpan="4"></td>
                  <td className={`${styles.cart__table__total} lg`}>
                    <span className="brand">TOTAL:</span>
                    <br />
                    <strong>
                      {money(
                        formik.values.reduce(function (val, item) {
                          return val + item.price * item.quantity;
                        }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <p className="heavy range-center">Your cart is empty</p>
          )}
          <div className="range-right">
            {formik.values.length > 0 && (
              <Button type="submit" disabled={formik.isSubmitting}>
                Save and proceed
              </Button>
            )}
          </div>
          <div>
            {formik.values.length > 0 && (
              <p className="heavy range-center">
                {!auth.user.isStaff ? 'Orders with a daily value of less than £100 won\'t be processed' : ''}
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
