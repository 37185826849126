import { useNavigate, useLocation } from 'react-router';
import querystring from 'query-string';
import { Formik, Form } from 'formik';
import { Grid, Col } from '../../components/grid/grid';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import useService from '../../hooks/useService';
import { fetchAllAccounts } from '../../services/accounts';

import styles from './transaction-history-account-filter.module.scss';

export default function TransactionHistoryAccountFilter() {
  const location = useLocation();
  const history = useNavigate();
  const query = querystring.parse(location.search);

  const { error, loading, response } = useService(fetchAllAccounts);

  function handleSubmit(values) {
    const newQuery = querystring.stringify({
      ...query,
      ...values
    });

    //console.log(values);

    history(`${location.pathname}?${newQuery}`);
  }

  return (
    <>
      <Formik
        initialValues={{ accountId: query.accountId || '' }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form className={styles.form}>
            <Grid gutters="sm" valign="bottom">
              <Col>
                <FormField
                  label="Account:"
                  type="select"
                  name="accountId"
                  id="accountId"
                  options={
                    response
                      ? response.map((acc) => ({
                          label: `${acc.erp_account_name} (${acc.erp_account_id})`,
                          value: acc.erp_account_id
                        }))
                      : []
                  }
                  onChange={submitForm}
                  disabled={loading || error}
                  placeholder={loading ? 'Loading accounts' : null}
                />
              </Col>
              <Col width="auto">
                <Button href={location.pathname} outlined>
                  Reset
                </Button>
              </Col>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
