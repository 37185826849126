import { Link, useParams } from 'react-router-dom';
import Container from '../../components/container/container';
import Button from '../../components/button/button';
import OrderStatus from '../../components/order-status/order-status';

import useService from '../../hooks/useService';
import useFlash from '../../hooks/useFlash';
import { fetchOrder, requestOrderInvoice } from '../../services/orders';
import { addCartItem } from '../../services/cart';
import { money } from '../../utils/string';
import { displayDate } from '../../utils/dates';

import styles from './orders.module.scss';
import AddToCart from '../../components/add-to-cart/add-to-cart';
import Icon from '../../components/icon/icon';
import ErrorMessage from '../../components/error-message/error-message';

export default function OrderDetailsPage() {
  const { id } = useParams();
  const { showFlash } = useFlash();
  const { error, loading, response: order } = useService(fetchOrder, id);

  const reorderableProducts = order?.products?.filter((p) => p.reorderable === true) || [];

  async function addAllProductsToCart() {
    if (
      !window.confirm(
        'Are you sure you wish to add all products from this order to your current cart? This will include previously ordered quantities/units.'
      )
    ) {
      return;
    }
    try {
      for (let product of order.products) {
        if (!product.reorderable) continue;
        await addCartItem({
          product_id: product.product_id,
          quantity: product.quantity,
          unit: product.unit
        });
      }
      showFlash('Successfully added all products to your cart!', 'success');
    } catch (error) {
      showFlash(error.message, 'error');
    }
  }

  async function requestInvoice() {
    try {
      await requestOrderInvoice(order.erp_order_id);
      showFlash(
        'Your request has been processed and an invoice should be emailed to you shortly.',
        'success'
      );
    } catch (error) {
      showFlash('Sorry, something went wrong. Please try again.', 'error');
      console.error(error);
    }
  }

  return (
    <main>
      <Container>
        <Link to="/orders">&laquo; Orders</Link>
        <h1 className="brand">Order Details</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {order && (
          <>
            <dl className={styles.orderDetails__details}>
              <dt>Order status:</dt>
              <dl>
                <OrderStatus order={order} />
              </dl>
              <dt>Order date:</dt>
              <dl>{displayDate(order.created_at, true)}</dl>
              <dt>PO number:</dt>
              <dl>{order.erp_po_number}</dl>
              <dt>Requested delivery date:</dt>
              <dl>{displayDate(order.erp_delivery_date)}</dl>
              <dt>Delivery notes:</dt>
              <dl>{order.erp_delivery_note}</dl>
            </dl>
            {order.status === 'accepted' && (
              <p>
                <Button onClick={requestInvoice} small>
                  <Icon>receipt_long</Icon> Request invoice
                </Button>
              </p>
            )}

            <table className={styles.orderDetails__products}>
              <thead className="brand">
                <tr>
                  <th>Product</th>
                  {/* <th className={styles.orderDetails__products__note}>Notes</th> */}
                  <th className={styles.orderDetails__products__quantity}>Qty</th>
                  <th className={styles.orderDetails__products__unit}>Unit</th>
                  <th className={styles.orderDetails__products__total}>Total</th>
                  <th className={styles.orderDetails__products__actions}></th>
                </tr>
              </thead>

              <tbody>
                {order.products.map((product) => (
                  <tr key={product.id} className={styles.orderDetails__products__product}>
                    <td>
                      {/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
                      <h5 className="heavy flush primary">{product.name}</h5>

                      <p>
                        {money(product.price)} {product.pUnit ? `(${product.pUnit})` : null}
                      </p>
                    </td>
                    <td>{product.quantity}</td>
                    <td>{product.unit}</td>
                    <td>{money(product.price * product.quantity)}</td>
                    <td className={styles.orderDetails__products__actions}>
                      <AddToCart
                        disabled={!product.reorderable}
                        product={product}
                        initialValues={{
                          product_id: product.product_id,
                          quantity: product.quantity,
                          unit: product.unit
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan="3"></td>
                  <td className={`${styles.orderDetails__products__total} lg`}>
                    <span className="brand">TOTAL:</span>
                    <br />
                    <strong>
                      {money(
                        order.products.reduce(function (val, item) {
                          return val + item.price * item.quantity;
                        }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            {reorderableProducts.length > 0 ? (
              <div className="range-right">
                <Button onClick={addAllProductsToCart}>
                  <Icon>add_shopping_cart</Icon> Add all products to your cart
                </Button>
              </div>
            ) : null}
          </>
        )}
        {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
      </Container>
    </main>
  );
}
