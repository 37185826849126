import { useNavigate } from 'react-router-dom';
import Container from '../../components/container/container';
import ClosureMessage from '../../components/closure-message/closure-message';

import CartBreadcrumbs from '../../components/cart-breadcrumbs/cart-breadcrumbs';
import useService from '../../hooks/useService';
import useAuth from '../../hooks/useAuth';
import useFlash from '../../hooks/useFlash';
import { updateCartSettings, fetchCartSettings } from '../../services/cart';
import CartSettingsForm from '../../forms/cart-settings-form/cart-settings-form';
//import formatRFC3339 from 'date-fns/formatRFC3339';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isDate from 'date-fns/isDate';

export default function CartOrderDetailsPage() {
  const history = useNavigate();
  const { auth } = useAuth();
  const { showFlash } = useFlash();
  const {
    error: cartError,
    loading: cartLoading,
    response: cartSettings
  } = useService(fetchCartSettings);

  async function handleSubmit(values, formik) {
    try {
      //convert text based delivery date to a real date
      if (values.delivery_date && values.delivery_date !== '' && auth.user.isStaff) {
        let fDate = parse(values.delivery_date, 'dd/MM/yyyy', new Date());
        //values.delivery_date = formatRFC3339(new Date(fDate), { fractionDigits: 3 });
        values.delivery_date = format(new Date(fDate), 'yyyy-MM-dd')+'T12:00:00.000Z';
      }
      await updateCartSettings(values);
      history('/cart/confirm');
    } catch (error) {
      showFlash(error.message, 'error');
    }
    formik.setSubmitting(false);
  }

  return (
    <main>
      <Container>
        <CartBreadcrumbs />
        <h1 className="brand">Order Details</h1>

        {cartLoading && <p>Loading...</p>}
        {cartError && <code>{cartError.message}</code>}

        {cartSettings && <CartSettingsForm onSubmit={handleSubmit} cartSettings={cartSettings} />}
        <ClosureMessage />
      </Container>
    </main>
  );
}
