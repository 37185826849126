import classnames from 'classnames';
import styles from './grid.module.scss';

export function Grid({
  gutters,
  smGutters,
  mdGutters,
  lgGutters,
  align,
  valign,
  className,
  children
}) {
  return (
    <div
      className={classnames(
        styles.grid,
        gutters ? styles[`grid--gutters-${gutters}`] : '',
        smGutters ? styles[`grid--sm-gutters-${smGutters}`] : '',
        mdGutters ? styles[`grid--md-gutters-${mdGutters}`] : '',
        lgGutters ? styles[`grid--lg-gutters-${lgGutters}`] : '',
        align ? styles[`grid--h-${align}`] : '',
        valign ? styles[`grid--v-${valign}`] : '',
        className
      )}
    >
      {children}
    </div>
  );
}

export function Col({ width, smWidth, mdWidth, lgWidth, className, children }) {
  return (
    <div
      className={classnames(
        styles.col,
        width ? styles[`col--w-${width}`] : '',
        smWidth ? styles[`col--sm-w-${smWidth}`] : '',
        mdWidth ? styles[`col--md-w-${mdWidth}`] : '',
        lgWidth ? styles[`col--lg-w-${lgWidth}`] : '',
        className
      )}
    >
      {children}
    </div>
  );
}
