import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '../../../components/container/container';
import ErrorMessage from '../../../components/error-message/error-message';
import UserForm from '../../../forms/user-form/user-form';
import useFlash from '../../../hooks/useFlash';
import useService from '../../../hooks/useService';
import { fetchUserById, updateUser, changeUserPassword } from '../../../services/users';

import styles from './users.module.scss';

export default function UsersEditPage() {
  const { id } = useParams();
  const { showFlash } = useFlash();
  const { error, loading, response } = useService(fetchUserById, id);
  const [submissionError, setSubmissionError] = useState(null);
  const [passwordChangeError, setPasswordChangeError] = useState(null);

console.log({response});

  async function handleUserUpdateSubmit(values, formik) {
    // console.log(values);
    setSubmissionError(null);

    const formattedValues = {
      ...values,
      permissions: JSON.stringify(values.permissions)
    };
    try {
      await updateUser(id, formattedValues);
      showFlash('User updated successfully!', 'success');
    } catch (error) {
      setSubmissionError(error.message);
    }
    formik.setSubmitting(false);
  }

  async function handlePasswordChange(values, formik, done) {
    setPasswordChangeError(null);
    try {
      await changeUserPassword(id, values);
      showFlash('User updated successfully!', 'success');
      done();
    } catch (error) {
      setPasswordChangeError(error.message);
    }
    formik.setSubmitting(false);
  }

  return (
    <main>
      <Container>
        <Link to="/admin/users" className="sm heavy">
          &laquo; Back
        </Link>
        <h1 className="brand">Edit User</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {response && (
          <UserForm
            values={{
              ...response,
              permissions: response.permissions
            }}
            onSubmit={handleUserUpdateSubmit}
            submissionError={submissionError}
            onPasswordChange={handlePasswordChange}
            passwordChangeError={passwordChangeError}
            className={styles.editUser__form}
          />
        )}
      </Container>
    </main>
  );
}
