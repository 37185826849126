import { Link } from 'react-router-dom';
import Container from '../../components/container/container';

import styles from './howto.module.scss';

export default function HowtoPage() {
  return (
    <main>
      <Container>
        <Link to="/login">&laquo; Login</Link>
        <h1 className="range-center brand">How To...</h1>

        <h3>Log in for the first time</h3>
        <p>The first time you login you should reset your password.</p>
        <p>
          NOTE: Please do not share your account with someone else, you can create a new user for
          them and it will not cost you anything to make as many users as you like.
        </p>

        <Link to="/password-reset">You can also click here to reset your password &raquo;</Link>
        <hr />

        <h3>Reset your password</h3>
        <p>
          You can reset your password as often as you like using the link below our login form.
          <Link to="/password-reset">"Lost Password"</Link>
        </p>
        <Link to="/password-reset">You can also click here to reset your password &raquo;</Link>
        <hr />

        <h3>Change your email</h3>
        <p>
          You can change your email address via the "My account" section, once you are logged into
          the site.
        </p>
        <p>
          NOTE: If you no longher have access to your old email address, you can contact sales to
          ask them to change it for you.
        </p>
        <Link to="/my-account">
          You can also click here to view your change your registered email &raquo;
        </Link>
        <hr />

        <h3>Place an Order</h3>
        <p>Placing an order is a case of adding items to your cart</p>
        <p>
          You can page through available products, display specials or search for products on the
          <Link to="/products">Products page</Link>
        </p>
        <p>
          When you have identified the product you would like, click the cart icon to add the
          product to the cart
        </p>
        <p>Select the unit and quantity you would like from the popup and click "Add"</p>
        <p>
          You can add the same product multiple times with different quantities or units if you wish
        </p>
        <p>When you are ready to continue, click "Save and proceed" on the cart page</p>
        <p>Select a delivery date and add any further information on the next page</p>
        <p>When you are done click "Save and proceed" to place your order</p>
        <p>
          When and order has been placed you will see a green bar appeat to confirm that the order
          has been placed
        </p>
        <p>NOTE: Orders with a total daily value of less than £100 will not be processed</p>
        <Link to="/products">You can also click here to start adding products &raquo;</Link>
        <hr />
        <h3>Reorder a product, or whole order, again</h3>
        <p>
          View an order in the <Link to="/orders">"My orders"</Link> page
        </p>
        <p>Next to each product click the cart icon to reorder the same product again</p>
        <p>
          If you prefer, at the bottom of the page you can click the "Add all" button to add all
          products to your cart again
        </p>
        <Link to="/orders">You can also click here to view the My Orders page &raquo;</Link>
        <hr />

        <h3>Check whether my order was accepted</h3>
        <p>
          Your order will display a coloured status next to it on the
          <Link to="/orders">"My orders"</Link> page
        </p>
        <Link to="/orders">You can also click here to view the My Orders page &raquo;</Link>
        <hr />

        <h3>Create more users</h3>
        <p>
          You can add more users to yourin the <Link to="/users">"Users"</Link> section{' '}
        </p>
        <Link to="/users">You can also click here to add more users to your account &raquo;</Link>
      </Container>
    </main>
  );
}
