import api from '../utils/api';

export function fetchAllAccounts(query = { paginate: 'off' }) {
  return api.get('/accounts', { params: query });
}

export function fetchAccountById(id) {
  return api.get(`/accounts/${id}`);
}

export function updateAccount(id, updates) {
  return api.put(`/accounts/${id}`, updates);
}

export function fetchAccountLocations(accountId) {
  return api.get(`/accounts/${accountId}/locations`);
}
