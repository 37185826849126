import { createContext, useReducer } from 'react';
import * as authService from '../services/auth';

const AuthContext = createContext();

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGGEDIN':
      localStorage.setItem('auth', JSON.stringify(action.data));
      return action.data;
    case 'AUTH_UPDATE':
      const auth = {
        ...state,
        ...action?.data,
        user: {
          ...state?.user,
          ...action?.data?.user,
          permissions: {
            ...state?.user?.permissions,
            ...action?.data?.user?.permissions
          }
        }
      };
      localStorage.setItem('auth', JSON.stringify(auth));
      return auth;
    case 'LOGGEDOUT':
      localStorage.removeItem('auth');
      return {};
    default:
      return state;
  }
}

function AuthProvider({ children }) {
  const initialAuth = localStorage.getItem('auth');
  const [auth, dispatch] = useReducer(authReducer, initialAuth ? JSON.parse(initialAuth) : {});

  async function login(credentials) {
    const { data } = await authService.login(credentials);
    dispatch({ type: 'LOGGEDIN', data });
  }
  async function logout() {
    await authService.logout();
    dispatch({ type: 'LOGGEDOUT' });
  }
  function updateAuthData(data) {
    dispatch({ type: 'AUTH_UPDATE', data });
  }

  const isAuthenticated = auth && auth.expires && new Date().getTime() / 1000 < auth.expires;

  return (
    <AuthContext.Provider value={{ auth, login, logout, updateAuthData, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
