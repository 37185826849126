import { Formik, Form } from 'formik';
import { canDeliverOnDate } from '../../utils/dates';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import useAuth from '../../hooks/useAuth';
import useService from '../../hooks/useService';
import { fetchStaffDeliveryDates, fetchDeliveryExclusions } from '../../services/cart';
import { fetchLocationByID } from '../../services/locations';
import ErrorMessage from '../../components/error-message/error-message';

// import startOfMonth from 'date-fns/startOfMonth';
// import nextWednesday from 'date-fns/nextWednesday';
// import addMonths from 'date-fns/addMonths';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import format from 'date-fns/format';

import validationSchema from './validationSchema';

import styles from './cart-settings-form.module.scss';

function nextStaffDeliveryDate(staffDeliveryDates) {
  //return blank values
  if (staffDeliveryDates === null || staffDeliveryDates.length === 0) {
    return '';
  }
  //calculate correct date using margin
  let nextDeliveryDate = parseISO(staffDeliveryDates[0].date);
  let nextOrderByDate = sub(nextDeliveryDate,JSON.parse(staffDeliveryDates[0].order_date_margin));
  if (!isBefore(new Date(), nextOrderByDate)) {
    if (staffDeliveryDates.length < 2) {
      return false;
    } else {
      nextDeliveryDate = parseISO(staffDeliveryDates[1].date);
    }
  }
  //retrun formatted date
  return format(new Date(nextDeliveryDate), 'dd/MM/yyyy');
}

export default function CartSettingsForm({ onSubmit, cartSettings }) {
  const { auth } = useAuth();
  const { error: locationError, response: location } = useService(
    fetchLocationByID,
    auth.user.location_default
  );
  const { error: staffDeliveryDatesError, response: staffDeliveryDates } = useService(
    fetchStaffDeliveryDates
  );
  const { error: deliveryExclusionsError, response: deliveryExclusions } = useService(
    fetchDeliveryExclusions
  );
  

  const staffPoNumber = () => {
    let poNumber = auth.user.staffNumber ? auth.user.staffNumber : '';
    if (auth.user.isStaff) {
      if (auth.user.firstname) {
        poNumber +=' '+auth.user.firstname.slice(0, 1);
      }
      if (auth.user.lastname) {
        poNumber +=' '+auth.user.lastname;
      }
      if (poNumber.length > 20) {
        poNumber = poNumber.slice(0,20);
      }
    }
    return poNumber;
  }

  if (locationError) {
    console.error(locationError);
    return (
      <ErrorMessage error="Unable to determine your delivery location. Please log out and in again. Your cart will be saved." />
    );
  }

  if (staffDeliveryDatesError) {
    console.error(staffDeliveryDatesError);
    return (
      <ErrorMessage error="Unable to find delivery dates. Please log out and in again. Your cart will be saved." />
    );
  }

  if (location && ((auth.user.isStaff && staffDeliveryDates) || (!auth.user.isStaff && deliveryExclusions))) {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          po_number: cartSettings?.po_number || staffPoNumber() || '',
          // delivery_location: cartSettings?.delivery_location || location?.code || '',
          delivery_date: auth.user.isStaff
            ? nextStaffDeliveryDate(staffDeliveryDates)
            : cartSettings?.delivery_date || '',
          delivery_note: cartSettings?.delivery_note || ''
        }}
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.settings}>
              <FormField
                type="text"
                name="po_number"
                id="po_number"
                label={auth.user.isStaff ? 'Staff Number:' : 'PO Number:'}
                maxLength="15"
                disabled={auth.user.isStaff}
                autoComplete="off"
              />

              {/* {locations?.length !== 1 && (
                <FormField
                  type="select"
                  name="delivery_location"
                  id="delivery_location"
                  label="Delivery location:"
                  placeholder={locationLoading ? 'Loading locations...' : null}
                  disabled={locationLoading || locationsError || locations?.length === 1}
                  options={locations.map(({ description, code }) => ({
                    label: description,
                    value: code
                  }))}
                />
              )} */}
              {auth.user.isStaff ? (
                <FormField
                  type="text"
                  name="delivery_date"
                  id="delivery_date"
                  label="Delivery date:"
                  disabled={true}
                  autoComplete="off"
                  value={nextStaffDeliveryDate(staffDeliveryDates)}
                />
              ) : (
                <FormField
                  type="date"
                  name="delivery_date"
                  id="delivery_date"
                  label="Delivery date:"
                  filterDate={(date) => canDeliverOnDate({location, exclusions: deliveryExclusions, date})}
                  disabled={!formik.values.delivery_location}
                  autoComplete="off"
                />
              )}
              <FormField
                type="textarea"
                name="delivery_note"
                id="delivery_note"
                label="Delivery notes:"
              />
              <div className="range-right">
                <Button outlined href="/cart">
                  &laquo; Back
                </Button>
                <Button type="submit" disabled={formik.isSubmitting || staffDeliveryDates === null}>
                  Save and proceed
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return <p>Loading...</p>;
}
