import Container from '../../components/container/container';
import MyAccountForm from '../../forms/my-account-form/my-account-form';
import useAuth from '../../hooks/useAuth';
import useFlash from '../../hooks/useFlash';
import { updateUser } from '../../services/users';

export default function MyAccountPage() {
  const { auth, updateAuthData } = useAuth();
  const { showFlash } = useFlash();

  async function handleUsernameSubmit(values, formik) {
    //console.log(`Change username to: ${values.username}`);
    try {
      formik.setSubmitting(false);
      await updateUser(auth.user.id, values);
      await updateAuthData({ user: values });
      showFlash('Username changed', 'success');
    } catch (error) {
      showFlash(error.message, 'error');
    }
  }

  return (
    <main>
      <Container>
        <h1 className="brand">My Account</h1>

        <MyAccountForm values={{ username: auth.user.username }} onSubmit={handleUsernameSubmit} />
      </Container>
    </main>
  );
}
