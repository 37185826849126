import { useLocation, Link } from 'react-router-dom';
import querystring from 'query-string';
import Button from '../../../components/button/button';
import Container from '../../../components/container/container';
import ErrorMessage from '../../../components/error-message/error-message';
import Table from '../../../components/table/table';
import Icon from '../../../components/icon/icon';
import SearchForm from '../../../forms/search-form/search-form';
import Pagination from '../../../components/pagination/pagination';
import useAuth from '../../../hooks/useAuth';
import useService from '../../../hooks/useService';
import { fetchAllAccounts } from '../../../services/accounts';

import styles from './accounts.module.scss';

export default function AccountsListPage() {
  const location = useLocation();
  const query = querystring.parse(location.search);

  const { error, loading, response } = useService(fetchAllAccounts, query);
  const { auth } = useAuth();

  if (!auth?.user?.isAdmin) {
    return (
      <main>
        <Container>
          <h1 className="brand">Page not found</h1>
          <Button href="/">Go home</Button>
        </Container>
      </main>
    );
  }

  return (
    <main>
      <Container>
        <h1 className="brand">Accounts</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        <SearchForm />
        {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
        {response && response.data.length > 0 && (
          <>
            <Table
              columns={[
                { label: 'ID', key: 'id', sortable: true, className: styles.accounts__account__id },
                {
                  label: 'ERP ID',
                  key: 'erp_account_id',
                  sortable: true,
                  className: styles.accounts__account__erpId
                },
                { label: 'Name', key: 'erp_account_name' },
                {
                  label: 'On Hold?',
                  key: 'onHold',
                  sortable: true,
                  getValue: (account) =>
                    account.onHold ? (
                      <strong className="error">Yes</strong>
                    ) : (
                      <strong className="success">No</strong>
                    ),
                  className: styles.accounts__account__onHold
                },
                {
                  label: '',
                  key: 'actions',
                  className: styles.accounts__account__actions,
                  getValue: (o) => (
                    <Link to={`/admin/accounts/${o.id}`}>
                      <Icon>open_in_new</Icon>
                    </Link>
                  )
                }
              ]}
              data={response.data}
            />
            <Pagination {...response.pagination} />
          </>
        )}
        {response && response.data.length === 0 && <p className="heavy">No accounts</p>}
      </Container>
    </main>
  );
}
