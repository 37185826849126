import { Formik, Form } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'query-string';
import { Grid, Col } from '../../components/grid/grid';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';

import styles from './search-form.module.scss';

export default function SearchForm() {
  const history = useNavigate();
  const location = useLocation();
  const query = querystring.parse(location.search);

  function updateSearchQuery(values) {
    const newQuery = querystring.stringify({
      ...query,
      ...values,
      page: 1
    });

    history(`${location.pathname}?${newQuery}`);
  }

  return (
    <Formik
      initialValues={{ search: query.search || '' }}
      enableReinitialize
      onSubmit={updateSearchQuery}
    >
      <Form className={styles.form}>
        <Grid gutters="sm" valign="bottom">
          <Col>
            <FormField type="search" name="search" id="search" label="Search:" />
          </Col>
          <Col width="auto">
            <Button type="submit">Go</Button>
          </Col>
        </Grid>
      </Form>
    </Formik>
  );
}
