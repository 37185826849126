import { Link, useParams } from 'react-router-dom';
import Container from '../../../components/container/container';
import ErrorMessage from '../../../components/error-message/error-message';
import AccountDeliverySettingsForm from '../../../forms/account-delivery-settings-form/account-delivery-settings-form';
import AccountOnHoldForm from '../../../forms/account-on-hold-form/account-on-hold-form';
import useService from '../../../hooks/useService';
import { fetchAccountById } from '../../../services/accounts';
import { fetchAccountLocations } from '../../../services/accounts';

export default function AccountsEditPage() {
  const { id } = useParams();
  const { accountError, accountLoading, response: account } = useService(fetchAccountById, id);
  const {
    locationsError,
    locationsLoading,
    response: locations
  } = useService(fetchAccountLocations, id);

  return (
    <main>
      <Container>
        <Link to="/admin/accounts" className="sm heavy">
          &laquo; Back
        </Link>
        {accountLoading && <p>Loading account...</p>}
        {accountError && <ErrorMessage error={accountError} />}
        {account && (
          <>
            <h1 className="brand">Edit &lsquo;{account.erp_account_name}&rsquo; Account</h1>
            <h4 className="brand">On Hold?</h4>
            <AccountOnHoldForm id={account.id} onHold={account.onHold} />

            <h4 className="brand" style={{ marginTop: '1.5rem' }}>
              Delivery Locations
            </h4>
            {locationsLoading && <p>Loading locations...</p>}
            {locationsError && <ErrorMessage error={locationsError} />}
            {locations && locations.length > 0 && (
              <AccountDeliverySettingsForm locations={locations} />
            )}
            {locations && locations.length === 0 && (
              <p className="heavy">No locations for this account</p>
            )}
          </>
        )}
      </Container>
    </main>
  );
}
