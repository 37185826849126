import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import { Grid, Col } from '../../components/grid/grid';
import Button from '../../components/button/button';

import initialValues from './initialValues';
import validationSchema from './validationSchema';
import SubmissionError from '../../components/submission-error/submission-error';

function createInitialValues(product, values) {
  return {
    ...initialValues,
    product_id: product.id,
    unit: product.unit,
    ...values
  };
}

export default function UserForm({
  values = {},
  product = {},
  onSubmit,
  onReset,
  submissionError
}) {
  return (
    <Formik
      initialValues={createInitialValues(product, values)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({ isSubmitting }) => (
        <Form>
          <div style={{ marginBottom: 20 }}>
            <Grid gutters="sm">
              <Col>
                <FormField type="number" name="quantity" id="quantity" label="Quantity" />
              </Col>
              <Col width="4">
                <FormField
                  type="select"
                  name="unit"
                  id="unit"
                  label="Unit"
                  options={product.orderableUnits.map((unit) => ({ label: unit, value: unit }))}
                  disabled={product.orderableUnits.length < 2}
                />
              </Col>
            </Grid>
          </div>

          <SubmissionError error={submissionError} />
          <div>
            <Button type="reset" outlined>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Add{isSubmitting && 'ing...'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
