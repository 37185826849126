import { Formik, Form } from 'formik';
import Button from '../../components/button/button';
import FormField from '../../components/form-field/form-field';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

export default function RequestPasswordResetForm({ onSubmit }) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form noValidate>
        <FormField type="email" name="username" id="username" label="Email" />

        <Button type="submit">Submit</Button>
      </Form>
    </Formik>
  );
}
