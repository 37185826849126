import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export default function useFlash() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const flash = location?.state?.flash;

  const dismissFlash = useCallback(() => {
    navigate(location.pathname);
  }, [navigate, location]);

  function showFlash(message, type) {
    let url = location.pathname;
    if (searchParams.keys.length > 0) {
      url += '?' + searchParams.toString();
    }
    navigate(url, { state: { flash: { message, type } } });
  }


  function redirectWithFlash(path, message, type) {
    navigate(path, { state: { flash: { message, type } } });
  }

  useEffect(() => {
    const timeout = flash ? setTimeout(dismissFlash, 3000) : null;
    return () => clearTimeout(timeout);
  }, [flash, dismissFlash]);

  return { flash, dismissFlash, showFlash, redirectWithFlash };
}
