import styles from './closure-message.module.scss';

export default function ClosureMessage() {
  // remember dates are in US format
  const now = new Date();
  const xmasCloseStart = new Date('12-20-2022 00:00:00');
  const xmasCloseStop = new Date('12-28-2022 08:00:00');
  // const nyeCloseStart = new Date('12-31-2022 17:00:00');
  // const nyeCloseStop = new Date('01-02-2023 08:00:00');

  if (xmasCloseStart < now && xmasCloseStop > now)
    return (
      <div className={styles.quicklinks}>
        <h3 className={styles.quicklinks__message}>
        Happy New Year from the team at Aubrey Allen
          <br />
          The Portal will not accept orders for delivery on the 30th, 31st, 1st or 2nd of January.
          <br />
          Normal service will be resumed for the 2nd January for deliveries on the 3rd January.
          <br />
          Thank you.
        </h3>
      </div>
    );

  // if (nyeCloseStart < now && nyeCloseStop > now)
  //   return (
  //     <div className={styles.quicklinks}>
  //       <h3 className={styles.quicklinks__message}>
  //         Happy New Year from the team at Aubrey Allen
  //         <br />
  //         Our offices will re-open on Tuesday 2nd January, please email sales@aubreyallen.co.uk
  //         should you need to contact us.
  //       </h3>
  //     </div>
  //   );

  // return null;
}
