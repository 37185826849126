import styles from './submission-error.module.scss';

export default function SubmissionError({ error = null }) {
  if (!error) return null;
  return (
    <p role="alert" className={styles.error}>
      {error}
    </p>
  );
}
