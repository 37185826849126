import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Container from '../../components/container/container';
import ClosureMessage from '../../components/closure-message/closure-message';

import styles from './home.module.scss';

import catalogueImage from './images/catalogue.jpg';
import specialsImage from './images/specials.jpg';
import accountImage from './images/account.jpg';
// import closedImage from './images/closed.png';

export default function Home() {
  const { auth } = useAuth();
  return (
    <main>
      <Container>
        <h1 className="brand">Aubrey Allen Ordering Portal</h1>
        {/* <pre>{JSON.stringify(auth, null, 2)}</pre>
         */}
        <div className={styles.quicklinks}>
          <Link className={styles.quicklinks__link} to={auth.user.isStaff ? 'products/top-50' : '/products'}>
            <img
              className={styles.quicklinks__link__background}
              src={catalogueImage}
              alt=""
              width="200"
              height="200"
              loading="lazy"
            />
            <span className={styles.quicklinks__link__label}>Products</span>
          </Link>
          <Link className={styles.quicklinks__link} to="/products/specials">
            <img
              className={styles.quicklinks__link__background}
              src={specialsImage}
              alt=""
              width="200"
              height="200"
              loading="lazy"
            />
            <span className={styles.quicklinks__link__label}>Specials</span>
          </Link>
          <Link className={styles.quicklinks__link} to="/my-account">
            <img
              className={styles.quicklinks__link__background}
              src={accountImage}
              alt=""
              width="200"
              height="200"
              loading="lazy"
            />
            <span className={styles.quicklinks__link__label}>My Account</span>
          </Link>
        </div>
        <ClosureMessage />
      </Container>
    </main>
  );
}
