import Container from '../../components/container/container';
import RequestPasswordResetForm from '../../forms/request-password-reset-form/request-password-reset-form';
import { requestPasswordReset } from '../../services/auth';
import useFlash from '../../hooks/useFlash';

import styles from './password-reset.module.scss';

export default function PasswordResetStartPage() {
  const { redirectWithFlash, showFlash } = useFlash();

  async function handleSubmit({ username }, formik) {
    try {
      await requestPasswordReset(username);
      redirectWithFlash(
        '/login',
        'If your email is registered with us we have just sent you a link to reset your password.',
        'success'
      );
    } catch (error) {
      showFlash(
        error?.response?.data?.message || 'Sorry, something went wrong. Please try again.',
        'error'
      );
    }
    formik.setSubmitting(false);
  }

  return (
    <main>
      <Container>
        <h1 className="range-center brand">Password Reset</h1>
        <div className={styles.form}>
          <RequestPasswordResetForm onSubmit={handleSubmit} />
        </div>
      </Container>
    </main>
  );
}
