import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import SubmissionError from '../../components/submission-error/submission-error';

import validationSchema from './validationSchema';

export default function MyAccountForm({ values, onSubmit, submissionError }) {
  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <FormField type="email" name="username" id="username" label="Username" />
          <SubmissionError error={submissionError} />
          <Button type="submit" disabled={isSubmitting || !dirty}>
            Sav{isSubmitting ? 'ing' : 'e'}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
