import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../../../components/container/container';
import UserForm from '../../../forms/user-form/user-form';
import { createUser } from '../../../services/users';

export default function UsersAddPage() {
  const history = useNavigate();
  const [submissionError, setSubmissionError] = useState(null);

  async function handleSubmit(values, formik) {
    setSubmissionError(null);
    const formattedValues = {
      ...values,
      permissions: JSON.stringify(values.permissions)
    };
    try {
      await createUser(formattedValues);
      history('/admin/users', {
        flash: { type: 'success', message: 'User added successfully!' }
      });
    } catch (error) {
      setSubmissionError(error?.response?.data || error.message);
      formik.setSubmitting(false);
    }
  }

  return (
    <main>
      <Container>
        <Link to="/admin/users" className="sm heavy">
          &laquo; Back
        </Link>
        <h1 className="brand">Add User</h1>
        <UserForm onSubmit={handleSubmit} submissionError={submissionError} />
      </Container>
    </main>
  );
}
