import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';

import initialValues from './initialValues';
import validationSchema from './validationSchema';

export default function ResetPasswordForm({ onSubmit }) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form noValidate>
        <FormField type="password" name="password" id="password" label="New password" />
        <FormField
          type="password"
          name="passwordConfirmation"
          id="passwordConfirmation"
          label="Confirm new password"
        />
        <Button type="submit">Submit</Button>
      </Form>
    </Formik>
  );
}
