import Container from '../../components/container/container';
import ResetPasswordForm from '../../forms/reset-password-form/reset-password-form';
import { useParams } from 'react-router-dom';
import { confirmPasswordReset } from '../../services/auth';
import useFlash from '../../hooks/useFlash';

import styles from './password-reset.module.scss';

export default function PasswordResetConfirmPage() {
  const { token } = useParams();
  const { redirectWithFlash, showFlash } = useFlash();

  async function handleSubmit({ password }, formik) {
    try {
      await confirmPasswordReset({ token, password });
      redirectWithFlash(
        '/login',
        'Your password has been changed. Please log in again.',
        'success'
      );
    } catch (error) {
      showFlash(error?.response?.data || 'Unable to change password', 'error');
    }
    formik.setSubmitting(false);
  }

  return (
    <main>
      <Container>
        <h1 className="range-center brand">Change your password</h1>
        <div className={styles.form}>
          <ResetPasswordForm onSubmit={handleSubmit} />
        </div>
      </Container>
    </main>
  );
}
