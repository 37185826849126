import { Link } from 'react-router-dom';
import { ReactComponent as LogoSvg } from './logo.svg';
import classnames from 'classnames';

import styles from './logo.module.scss';

export default function Logo({ className = null }) {
  return (
    <Link to="/" className={classnames(styles.logo, className)}>
      <LogoSvg />
    </Link>
  );
}
