import * as Yup from 'yup';
import { VALIDATION_REQUIRED, VALIDATION_EMAIL } from '../../constants';

const validationSchema = Yup.object().shape({
  username: Yup.string().email(VALIDATION_EMAIL).required(VALIDATION_REQUIRED),
  location_default: Yup.string().required(VALIDATION_REQUIRED),
  permissions: Yup.object().shape({
    canManageAccounts: Yup.string().required(VALIDATION_REQUIRED),
    canManageUsers: Yup.string().required(VALIDATION_REQUIRED),
    canOrder: Yup.string().required(VALIDATION_REQUIRED),
    canViewCredit: Yup.string().required(VALIDATION_REQUIRED),
    canViewHistory: Yup.string().required(VALIDATION_REQUIRED),
    canViewPricing: Yup.string().required(VALIDATION_REQUIRED),
    canViewInvoicing: Yup.string().required(VALIDATION_REQUIRED)
  })
});

export default validationSchema;

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required(VALIDATION_REQUIRED)
});
