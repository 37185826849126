import classnames from 'classnames';
import useFlash from '../../hooks/useFlash';

import styles from './flash-message.module.scss';

export default function FlashMessage() {
  const { flash, dismissFlash } = useFlash();

  if (!flash) {
    return null;
  }

  return (
    <div
      role="alert"
      className={classnames(styles.flash, styles[`flash--${flash.type}`])}
      onClick={dismissFlash}
    >
      {flash.message}
    </div>
  );
}
