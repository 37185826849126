import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import Table from '../../components/table/table';
import { Grid, Col } from '../../components/grid/grid';
import SubmissionError from '../../components/submission-error/submission-error';
import initialValues from './initialValues';
import validationSchema from './validationSchema';

import styles from './account-delivery-settings-form.module.scss';
import { updateLocation } from '../../services/locations';
import { useState } from 'react';
import useFlash from '../../hooks/useFlash';

function DeliveryDayFields({ label, day, formik }) {
  const enabled = formik?.values?.delivery_config?.delivery_days?.[day]?.toString() === 'true';
  return (
    <>
      <FormField
        label={label}
        type="checkbox"
        name={`delivery_config.delivery_days.${day}`}
        id={`delivery_config.delivery_days.${day}`}
        className={`${styles.location__delivery__day} heavy`}
        onChange={(e) => {
          if (!e.target.checked) {
            formik.setFieldValue(`delivery_config.delivery_cutoffs.${day}`, '19:00');
          }
        }}
      />
      <div style={{ opacity: enabled ? 1 : 0.2 }}>
        <FormField
          label="Cutoff"
          type="text"
          name={`delivery_config.delivery_cutoffs.${day}`}
          id={`delivery_config.delivery_cutoffs.${day}`}
          className={styles.location__delivery__cutoff}
          disabled={!enabled}
        />
      </div>
    </>
  );
}

export default function AccountDeliverySettingsForm({ locations }) {
  const [submissionError, setSubmissionError] = useState(null);
  const { showFlash } = useFlash();

  async function handleSubmit(values, formik) {
    setSubmissionError(null);
    try {
      await updateLocation(values.id, { delivery_config: JSON.stringify(values.delivery_config) });
      showFlash('Delivery location settings updated!', 'success');
    } catch (error) {
      setSubmissionError({ location: values.id, error: error?.response?.data || error.message });
    }
    formik.setSubmitting(false);
  }

  return (
    <Table
      columns={[
        {
          label: 'Description',
          key: 'description',
          className: styles.location__description
        },
        {
          label: 'Code',
          key: 'code',
          className: styles.location__code
        },
        {
          label: 'Delivery Settings',
          key: 'deliverySettings',
          getValue: (location) => (
            <Formik
              initialValues={{
                id: location.id,
                delivery_config: {
                  delivery_days: {
                    ...initialValues.delivery_config.delivery_days,
                    ...location.delivery_config.delivery_days
                  },
                  delivery_cutoffs: {
                    ...initialValues.delivery_config.delivery_cutoffs,
                    ...location.delivery_config.delivery_cutoffs
                  }
                }
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
              key={location.id}
            >
              {(formik) => (
                <Form>
                  <Grid className={styles.location__delivery}>
                    <Col>
                      <DeliveryDayFields label="Mon" day="monday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Tue" day="tuesday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Wed" day="wednesday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Thurs" day="thursday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Fri" day="friday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Sat" day="saturday" formik={formik} />
                    </Col>
                    <Col>
                      <DeliveryDayFields label="Sun" day="sunday" formik={formik} />
                    </Col>
                    <Col width="auto">
                      <Button type="submit" disabled={formik.isSubmitting} small>
                        Sav{formik.isSubmitting ? 'ing...' : 'e'}
                      </Button>
                    </Col>
                  </Grid>
                  {submissionError && submissionError.id === location.id && (
                    <SubmissionError error={submissionError.error} />
                  )}
                </Form>
              )}
            </Formik>
          )
        }
      ]}
      data={locations}
    />
  );
}
