import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import styles from './cart-breadcrumbs.module.scss';

export default function CartBreadcrumbs() {
  const { pathname } = useLocation();
  return (
    <div className={styles.breadcrumbs}>
      <span
        className={classnames(
          styles.breadcrumbs__link,
          pathname === '/cart' && styles['breadcrumbs__link--active']
        )}
      >
        Cart
      </span>
      <span className={styles.breadcrumbs__divider}>&raquo;</span>
      <span
        className={classnames(
          styles.breadcrumbs__link,
          pathname === '/cart/order-details' && styles['breadcrumbs__link--active']
        )}
      >
        Order Details
      </span>
      <span className={styles.breadcrumbs__divider}>&raquo;</span>
      <span
        className={classnames(
          styles.breadcrumbs__link,
          pathname === '/cart/confirm' && styles['breadcrumbs__link--active']
        )}
      >
        Confirm & Order
      </span>
    </div>
  );
}
