import classnames from 'classnames';

import styles from './icon.module.scss';

/**
 * This is just a wrapper that uses Material Icons
 * @link https://fonts.google.com/icons
 */
export default function Icon({ children, className }) {
  return (
    <span className={classnames('material-icons-outlined', styles.icon, className)}>
      {children}
    </span>
  );
}
