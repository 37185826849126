import classnames from 'classnames';
import querystring from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './table.module.scss';

export default function Table({ columns, data, className }) {
  const history = useNavigate();
  const { pathname, search } = useLocation();
  const query = querystring.parse(search);
  const { sortBy, order } = query;

  function changeSorting(newSortBy) {
    const newQuery = { ...query, sortBy: newSortBy };
    if (sortBy === newSortBy) {
      newQuery.order = order === 'desc' ? 'asc' : 'desc';
    } else {
      newQuery.order = 'desc';
    }
    history(`${pathname}?${querystring.stringify(newQuery)}`);
  }

  return (
    <table className={classnames(styles.table, className)}>
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col.key} className={col.className}>
              {col.sortable ? (
                <button
                  type="button"
                  className={classnames(
                    styles.sortControl,
                    sortBy === col.key && styles[`sortControl--${order}`]
                  )}
                  title={`Sort by ${col.label}`}
                  onClick={() => changeSorting(col.key)}
                >
                  {col.label}
                </button>
              ) : (
                col.label
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col) => (
              <td key={`row--${rowIndex}--${col.key}`} className={col.className}>
                {typeof col.getValue === 'function' ? col.getValue(row) : row[col.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
