import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Grid, Col } from '../grid/grid';
import classnames from 'classnames';
import Logo from '../logo/logo';
import Container from '../container/container';
import Button from '../button/button';
import Icon from '../icon/icon';

import styles from './header.module.scss';
import useAuth from '../../hooks/useAuth';

export default function Header() {
  const { auth, isAuthenticated, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function openMenu() {
    setIsMenuOpen(true);
  }
  function closeMenu() {
    setIsMenuOpen(false);
  }

  function MobileControls() {
    return (
      <div className={styles['header__mbl-controls']}>
        <Link to="/cart" className={styles['header__mbl-controls__control']} onClick={closeMenu}>
          <Icon outlined>shopping_cart</Icon>
        </Link>
        <button
          onClick={isMenuOpen ? closeMenu : openMenu}
          className={styles['header__mbl-controls__control']}
        >
          <Icon>{isMenuOpen ? 'close' : 'menu'}</Icon>
        </button>
      </div>
    );
  }

  return (
    <header className={styles.header}>
      <Container className={styles.header__content}>
        <Grid gutters="md" valign="center">
          <Col width="auto">
            <Logo className={styles.header__logo} />
          </Col>
          {isAuthenticated && (
            <>
              <Col>
                <nav
                  className={classnames(
                    styles.header__menu,
                    isMenuOpen && styles['header__menu--open']
                  )}
                >
                  <MobileControls />
                  <ul>
                    {auth?.user?.isAdmin === true &&
                      auth?.user?.permissions?.canManageAccounts === true && (
                        <li className={styles.header__menu__item}>
                          <NavLink to="/admin/accounts" onClick={closeMenu}>
                            Accounts
                          </NavLink>
                        </li>
                      )}
                    {auth?.user?.permissions?.canManageUsers === true && (
                      <li className={styles.header__menu__item}>
                        <NavLink to="/admin/users" onClick={closeMenu}>
                          Users
                        </NavLink>
                      </li>
                    )}
                    {auth?.user?.isAdmin === true && (
                      <li className={styles.header__menu__item}>
                        <NavLink to="/admin/history" onClick={closeMenu}>
                          Transaction History
                        </NavLink>
                      </li>
                    )}
                    <li className={styles.header__menu__item}>
                      <NavLink to="/orders" onClick={closeMenu}>
                        My Orders
                      </NavLink>
                    </li>
                    <li className={styles.header__menu__item}>
                      <NavLink to={auth.user.isStaff ? 'products/top-50' : '/products'} exact="true" onClick={closeMenu}>
                        Products
                      </NavLink>
                    </li>
                    <li className={styles.header__menu__item}>
                      <NavLink to="/products/specials" onClick={closeMenu}>
                        Specials
                      </NavLink>
                    </li>
                    <li className={styles.header__menu__item}>
                      <NavLink to="/cart" onClick={closeMenu}>
                        Cart
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col width="auto">
                <Button
                  type="button"
                  outlined
                  small
                  className={styles.header__logout}
                  onClick={logout}
                >
                  Log out
                </Button>

                <MobileControls />
              </Col>
            </>
          )}
        </Grid>
      </Container>
    </header>
  );
}
