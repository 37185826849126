import { useState } from 'react';
import Icon from '../icon/icon';
import Modal from '../modal/modal';
import AddToCartForm from '../../forms/add-to-cart-form/add-to-cart-form';
import { addCartItem } from '../../services/cart';
import useFlash from '../../hooks/useFlash';
import { money } from '../../utils/string';

import styles from './add-to-cart.module.scss';

export default function AddToCart({ product, initialValues = {}, disabled = false }) {
  const { showFlash } = useFlash();
  const [showModal, setShowModal] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }
  async function confirmAddToCart(values, formik) {
    try {
      await addCartItem(values);
      closeModal();
      showFlash('Product added to cart', 'success');
    } catch (error) {
      setSubmissionError(error?.response?.data || error.message);
      formik.setSubmitting(false);
    }
  }

  return (
    <>
      <button
        type="button"
        aria-label="Add to cart"
        onClick={openModal}
        className={styles.a2cButton}
        disabled={disabled}
        title={
          disabled ? 'This product is not available to order' : 'Add this product to your cart'
        }
      >
        <Icon>add_shopping_cart</Icon>
      </button>

      {!disabled && (
        <Modal isOpen={showModal} onClose={closeModal}>
          <h4 className="brand tight">
            Add &lsquo;{product.name || '[PRODUCT NAME]'}&rsquo; to cart
          </h4>
          <p>
            {money(product.price)} {product.unit ? `(${product.unit})` : null}
          </p>
          <AddToCartForm
            onSubmit={confirmAddToCart}
            onReset={closeModal}
            submissionError={submissionError}
            product={product}
            values={initialValues}
          />
        </Modal>
      )}
    </>
  );
}
