import Button from '../components/button/button';
import Container from '../components/container/container';

export default function NotFoundPage() {
  return (
    <main>
      <Container>
        <h1 className="brand">Page not found</h1>
        <Button href="/">Go home</Button>
      </Container>
    </main>
  );
}
