import axios from 'axios';

// Set up base axios options
const axiosOptions = {
  baseURL: '/api',
  headers: {}
};

// Create an axios instance to be used via `api`
const api = axios.create(axiosOptions);

// Handle 401 responses globally
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/auth/login'
    ) {
      localStorage.removeItem('auth');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
