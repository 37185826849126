import { useLocation, Link } from 'react-router-dom';
import querystring from 'query-string';
import Container from '../../components/container/container';
import ErrorMessage from '../../components/error-message/error-message';
import Table from '../../components/table/table';
import OrderStatus from '../../components/order-status/order-status';
import Pagination from '../../components/pagination/pagination';
import Icon from '../../components/icon/icon';
import { money } from '../../utils/string';
import { displayDate } from '../../utils/dates';
import { fetchAllOrders } from '../../services/orders';
import useService from '../../hooks/useService';

import styles from './orders.module.scss';

export default function OrdersListPage() {
  const location = useLocation();
  let { page = 1 } = querystring.parse(location.search);

  const { error, loading, response } = useService(fetchAllOrders, { page });

  return (
    <main>
      <Container>
        <h1 className="brand">My Orders</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {response?.data?.length > 0 && (
          <>
            <Table
              columns={[
                // { label: 'ID', key: 'order_id', className: styles.ordersList__order__id },

                {
                  label: 'Order Date',
                  key: 'created_at',
                  className: styles.ordersList__order__date,
                  sortable: true,
                  getValue: (o) => displayDate(o.created_at, true)
                },
                {
                  label: 'Total',
                  key: 'order_total',
                  getValue: (o) => money(o.order_total ? o.order_total : 0),
                  sortable: true
                },
                {
                  label: 'PO Number',
                  key: 'erp_po_number'
                },
                {
                  label: 'Delivery Date',
                  key: 'erp_delivery_date',
                  className: styles.ordersList__order__deliveryDate,
                  getValue: (o) => displayDate(o.erp_delivery_date),
                  sortable: true
                },
                {
                  label: 'Status',
                  key: 'status',
                  className: styles.ordersList__order__status,
                  getValue: (o) => <OrderStatus order={o} />
                },
                {
                  label: '',
                  key: 'actions',
                  className: styles.ordersList__order__actions,
                  getValue: (o) => (
                    <Link to={`/orders/${o.order_id}`}>
                      <Icon>open_in_new</Icon>
                    </Link>
                  )
                }
              ]}
              data={response.data}
            />
            <Pagination {...response.pagination} />
          </>
        )}
        {response && response.data.length === 0 && <p className="heavy">No orders.</p>}
      </Container>
    </main>
  );
}
