import { useNavigate } from 'react-router-dom';
import Container from '../../components/container/container';
import ErrorMessage from '../../components/error-message/error-message';
import CartBreadcrumbs from '../../components/cart-breadcrumbs/cart-breadcrumbs';
import CartListingForm from '../../forms/cart-list-form/cart-list-form';
import useService from '../../hooks/useService';
import useFlash from '../../hooks/useFlash';
import useAuth from '../../hooks/useAuth';
import { fetchCart, updateCartItem, deleteCartItem } from '../../services/cart';
import WarningMessage from '../../components/warning-message/warning-message';

export default function CartPage() {
  const history = useNavigate();
  const { showFlash } = useFlash();
  const { auth } = useAuth();
  const { error, loading, response: cartItems, reloadData } = useService(fetchCart);
  const unavailableItems = (cartItems || []).filter((item) => item.price === null);

  async function onItemDelete(id, formik) {
    try {
      await deleteCartItem(id);
      if (formik) {
        formik.setValues(formik.values.filter((v) => v.id !== id));
      }
      reloadData();
    } catch (error) {
      showFlash(error.message, 'error');
    }
  }

  async function updateCartAndProceed(values) {
    try {
      // Update any cart items remaining in the form
      for (const item of values) {
        await updateCartItem(item.id, {
          quantity: item.quantity,
          unit: item.unit
        });
      }
      if (unavailableItems.length) {
        for (const item of unavailableItems) {
          await deleteCartItem(item.id);
        }
      }
      history('/cart/order-details');
    } catch (error) {
      showFlash(error.message, 'error');
    }
  }

  return (
    <main>
      <Container>
        <CartBreadcrumbs />
        <h1 className="brand">Cart</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {unavailableItems.length > 0 ? (
          <WarningMessage warning="One or more items in your cart are no longer available and have been removed. Please review your cart before proceeding." />
        ) : null}

        {cartItems && (
          <CartListingForm
            onSubmit={updateCartAndProceed}
            onItemDelete={onItemDelete}
            cartItems={cartItems.filter((i) => i.price !== null)}
            auth={auth}
          />
        )}
      </Container>
    </main>
  );
}
