import { useLocation } from 'react-router';
import querystring from 'query-string';
import _startcase from 'lodash.startcase';
import Container from '../../../components/container/container';
import ErrorMessage from '../../../components/error-message/error-message';
import { Grid, Col } from '../../../components/grid/grid';
import Pagination from '../../../components/pagination/pagination';
import Table from '../../../components/table/table';
import Button from '../../../components/button/button';
import UsersListAccountFilter from '../../../forms/users-list-account-filter/users-list-account-filter';
import useAuth from '../../../hooks/useAuth';
import useService from '../../../hooks/useService';
import { fetchAllUsers } from '../../../services/users';

import styles from './users.module.scss';

export default function UsersListPage() {
  const location = useLocation();
  let { page = 1, accountId } = querystring.parse(location.search);

  const { error, loading, response } = useService(fetchAllUsers, { page, accountId });
  const { auth } = useAuth();

  return (
    <main>
      <Container>
        <Grid gutters="md" valign="center">
          <Col>
            <h1 className="brand">Users</h1>
          </Col>
          <Col width="auto">
            <Button href="/admin/users/add">+ Add User</Button>
          </Col>
        </Grid>
        {auth.user.isAdmin && <UsersListAccountFilter />}
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {/* {response && <pre>{JSON.stringify(response, null, 2)}</pre>} */}
        {response && response.data.length > 0 && (
          <>
            <Table
              columns={[
                { label: 'ID', key: 'id', className: styles.users__user__id },
                //{ label: 'ERP ID', key: 'erp_account_id', className: styles.users__user__erpId },
                {
                  label: 'Username',
                  key: 'username'
                },
                {
                  label: 'Account',
                  key: 'erp_account_name',
                  getValue: (user) => {
                    return `${user.erp_account_name} (${user.acc_erp_id})`;
                  }
                },
                {
                  label: 'Permissions',
                  key: 'permissions',
                  className: styles.users__user__permissions,
                  getValue: (user) => {
                    const permissions = user.permissions || {};
                    return (
                      <ul>
                        {Object.entries(permissions).map(([permission, enabled]) =>
                          enabled.toString() === 'true' ? (
                            <li key={`${user.id}--${permission}`}>
                              {_startcase(permission.replace('can', ''))}
                            </li>
                          ) : null
                        )}
                      </ul>
                    );
                  }
                },
                {
                  label: '',
                  key: 'actions',
                  getValue: (usr) => (
                    <Button href={`/admin/users/${usr.id}`} small outlined>
                      Edit
                    </Button>
                  ),
                  className: styles.users__user__actions
                }
              ]}
              data={response.data}
            />
            <Pagination {...response.pagination} />
          </>
        )}
        {response && response.data.length === 0 && <p className="heavy">No users</p>}
      </Container>
    </main>
  );
}
