import api from '../utils/api';

/**
 * Login by username and password
 * @param {Object} credentials
 * @returns {Promise}
 */
export function login({ username, password }) {
  return api.post('/auth/login', { username, password });
}

/**
 * Logout
 * @returns {Promise}
 */
export function logout() {
  return api.post('/auth/logout');
}

export function requestPasswordReset(username) {
  return api.post('/auth/reset-password', { username });
}

export function confirmPasswordReset({ token, password }) {
  return api.post('/auth/reset-password/confirm', { token, password });
}
