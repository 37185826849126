import { Link, useParams } from 'react-router-dom';
import Container from '../../../components/container/container';
import Button from '../../../components/button/button';
import OrderStatus from '../../../components/order-status/order-status';
import useService from '../../../hooks/useService';
import useFlash from '../../../hooks/useFlash';
import { fetchOrder, fetchEventLog, resendOrderToNucleus } from '../../../services/orders';
import { money } from '../../../utils/string';
import { displayDate } from '../../../utils/dates';

import styles from './orders.module.scss';
import Icon from '../../../components/icon/icon';
import ErrorMessage from '../../../components/error-message/error-message';

export default function OrderDetailsPage() {
  const { id } = useParams();
  const { showFlash } = useFlash();
  const { error, loading, response: order } = useService(fetchOrder, id);
  const { eventError, eventLoading, response: events } = useService(fetchEventLog, id);

  async function resendOrder() {
    try {
      await resendOrderToNucleus(order.order_id);
      showFlash(
        'Order has been resent to the ERP system. Please note this can take a few seconds before I recieve a result',
        'success'
      );
    } catch (error) {
      showFlash('Sorry, something went wrong. Please try again.', 'error');
      console.error(error);
    }
  }

  return (
    <main>
      <Container>
        <Link to="/admin/history">&laquo; Transaction history</Link>
        <h1 className="brand">Order Details</h1>
        {loading && <p>Loading...</p>}
        {error && <ErrorMessage error={error} />}
        {order && (
          <>
            <dl className={styles.orderDetails__details}>
              <dt>Order status:</dt>
              <dl>
                <OrderStatus order={order} />
              </dl>
              <dt>Order date:</dt>
              <dl>{displayDate(order.created_at, true)}</dl>
              <dt>Account name (code):</dt>
              <dl>{order.account.erp_account_name} ({order.account.erp_account_id})</dl>
              <dt>Location name (code):</dt>
              <dl>{order.erp_address_desc} ({order.erp_address_code})</dl>
              <dt>User email:</dt>
              <dl>{order.customer.username}</dl>
              <dt>PO number:</dt>
              <dl>{order.erp_po_number}</dl>
              <dt>Requested delivery date:</dt>
              <dl>{displayDate(order.erp_delivery_date)}</dl>
              <dt>Delivery notes:</dt>
              <dl>{order.erp_delivery_note}</dl>
            </dl>
            {order.status !== 'accepted' && (
              <p>
                <Button onClick={resendOrder} small>
                  <Icon>refresh</Icon> Re-send order to ERP
                </Button>
              </p>
            )}

            <table className={styles.orderDetails__products}>
              <thead className="brand">
                <tr>
                  <th>Product</th>
                  {/* <th className={styles.orderDetails__products__note}>Notes</th> */}
                  <th className={styles.orderDetails__products__quantity}>Qty</th>
                  <th className={styles.orderDetails__products__unit}>Unit</th>
                  <th className={styles.orderDetails__products__total}>Total</th>
                  <th className={styles.orderDetails__products__actions}></th>
                </tr>
              </thead>

              <tbody>
                {order.products.map((product) => (
                  <tr key={product.id} className={styles.orderDetails__products__product}>
                    <td>
                      {/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
                      <h5 className="heavy flush primary">{product.name}</h5>

                      <p>
                        {money(product.price)} {product.pUnit ? `(${product.pUnit})` : null}
                      </p>
                    </td>
                    <td>{product.quantity}</td>
                    <td>{product.unit}</td>
                    <td>{money(product.price * product.quantity)}</td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan="3"></td>
                  <td className={`${styles.orderDetails__products__total} lg`}>
                    <span className="brand">TOTAL:</span>
                    <br />
                    <strong>
                      {money(
                        order.products.reduce(function (val, item) {
                          return val + item.price * item.quantity;
                        }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>

            <h1 className="brand">Transaction record</h1>
            {eventLoading && <p>Loading...</p>}
            {eventError && <ErrorMessage error={eventError} />}
            {events && (
              <table className={styles.orderDetails__logs}>
                <thead className="brand">
                  <tr>
                    <th className={styles.orderDetails__logs__datetime}>Timestamp</th>
                    <th>Event log</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event) => (
                    <tr key={event.id} className={styles.orderDetails__logs__log}>
                      <td>{displayDate(event.datetime, true)}</td>
                      <td>{event.log_data}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </Container>
    </main>
  );
}
