import * as Yup from 'yup';
import { VALIDATION_REQUIRED, VALIDATION_MIN_1, VALIDATION_GT_0 } from '../../constants';

const validationSchema = Yup.object().shape({
  unit: Yup.string().required(VALIDATION_REQUIRED),
  quantity: Yup.number()
    .required(VALIDATION_REQUIRED)
    .when('unit', {
      is: 'Each',
      then: ($schema) => $schema.min(1, VALIDATION_MIN_1),
      otherwise: ($schema) => $schema.moreThan(0, VALIDATION_GT_0)
    })
});

export default validationSchema;
