import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import isBefore from 'date-fns/isBefore';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';

const DAY_NAMES = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function getDayNumberByName(name) {
  return DAY_NAMES.indexOf(name);
}

export function getDayNameByNumber(number) {
  return DAY_NAMES[number];
}

export function canDeliverOnDate({location, exclusions, date}) {
  const now = new Date();
  const deliveryConfig = location?.delivery_config || {};

  // Exclude today and earlier
  if (isToday(date) || isBefore(date, now)) {
    return false;
  }

  //check for exclusions by day name
  if (exclusions && exclusions.days) {
    for (let day of exclusions.days) {
      if (getDay(date) === getDayNumberByName(day.day)) {
        return false;
      }
    }
  }

  //check for exclusions by specific date
  if (exclusions && exclusions.dates) {
    for (let exDate of exclusions.dates) {
      if (differenceInDays(date, new Date(parseISO(exDate.date))) === 0) {
        return false;
      }
    }
  }

  // Exclude tomorrow if now is after a defined cutoff time
  if (isTomorrow(date)) {
    const todayName = getDayNameByNumber(getDay(now));
    const cutoff = deliveryConfig?.delivery_cutoffs?.[todayName];
    if (cutoff) {
      const [hours, mins] = cutoff.split(':');
      const cutoffTime = setSeconds(setMinutes(setHours(now, Number(hours)), Number(mins)), 0);
      return isBefore(now, cutoffTime);
    }
  }

  // Exclude all other days that arent enabled
  const deliveryDays = Object.entries(location.delivery_config.delivery_days)
    .filter(([day, enabled]) => enabled)
    .map(([day]) => getDayNumberByName(day));

  return deliveryDays.includes(getDay(date));
}

// export function getEarliestDeliveryDate(location) {
//   let date = new Date();
//   let earliestDeliveryDate = null;
//   let exclusions = {};

//   while (!earliestDeliveryDate) {
//     if (canDeliverOnDate({location, exclusions, date})) {
//       earliestDeliveryDate = date;
//     }
//     date = startOfDay(addDays(date, 1));
//   }

//   return earliestDeliveryDate;
// }

export function displayDate(date, includeTime = false) {
  let dateFormat = 'dd/MM/yyyy';
  if (includeTime) dateFormat += ' HH:mm';
  return format(new Date(date), dateFormat);
}
