import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export default function useService(service, ...args) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function loadData() {
    setLoading(true);
    service(...args)
      .then((response) => {
        setError(null);
        setLoading(false);
        setResponse(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setResponse(null);
        setLoading(false);
      });
  }

  useDeepCompareEffect(() => {
    loadData();
  }, [service, ...args]);

  return { error, loading, response, reloadData: loadData };
}
