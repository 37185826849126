import api from '../utils/api';

export function fetchCart() {
  return api.get('/cart');
}

export function addCartItem(values) {
  return api.post('/cart', values);
}

export function updateCartItem(id, values) {
  return api.put(`/cart/${id}`, values);
}

export function deleteCartItem(id) {
  return api.delete(`/cart/${id}`);
}

export function addProductToCart(values) {
  return api.post('/cart', values);
}

export function fetchCartSettings() {
  return api.get('/cart/settings');
}

export function fetchStaffDeliveryDates() {
  return api.get('/cart/staffDeliveryDates');
}

export function fetchDeliveryExclusions() {
  return api.get('/cart/deliveryExclusions');
}

export function updateCartSettings(settings) {
  return api.post('/cart/settings', settings);
}
