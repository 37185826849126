import { useState } from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../components/form-field/form-field';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import Icon from '../../components/icon/icon';
import SubmissionError from '../../components/submission-error/submission-error';
import { fetchLocationsByAccount } from '../../services/locations';
import useService from '../../hooks/useService';
import useAuth from '../../hooks/useAuth';

import initialValues from './initialValues';
import validationSchema, { passwordValidationSchema } from './validationSchema';
import { fetchAllAccounts } from '../../services/accounts';

export default function UserForm({
  values,
  onSubmit,
  submissionError,
  onPasswordChange,
  passwordChangeError,
  className
}) {
  const canEditPassword = typeof onPasswordChange === 'function';
  const auth = useAuth();

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          erp_account_id: auth?.auth?.user?.isAdmin ? '' : auth?.auth?.user?.erp_account_id,
          ...values,
          location_default: values?.location_default ?? '' // could be null, in which case convert to an empty string
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className={className}>
            <FormField type="email" name="username" id="username" label="Username" />
            <FormField type="text" name="firstname" id="firstname" label="First Name (Staff users only)" />
            <FormField type="text" name="lastname" id="lastname" label="Last Name (Staff users only)" />
            <FormField type="text" name="staffNumber" id="staffNumber" label="Staff Number (Staff users only)" />
            {canEditPassword && (
              <p>
                <button
                  type="button"
                  className="heavy primary sm"
                  onClick={() => setShowPasswordModal(true)}
                >
                  <Icon>lock_reset</Icon> Change Password
                </button>
              </p>
            )}
            {auth?.auth?.user?.isAdmin && <AccountSelect />}
            {values.erp_account_id && <LocationSelect erp_account_id={values.erp_account_id} />}
            <h4 className="brand tight">Permissions</h4>
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canManageAccounts"
              id="canManageAccounts"
              label="Manage Accounts"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              Manage Accounts determines whether this user to control account level settings on the
              website. Please note that these will be overwritten by changes made in the ERP system.
            </p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canManageUsers"
              id="canManageUsers"
              label="Manage Users"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              Manage Users determines whether this user is able to create, manage or disable other
              users on the same account.
            </p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canOrder"
              id="canOrder"
              label="Create Orders"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              Create Orders allows a user to place orders on to the ERP system, if disabled the user
              can still view product information without placing orders
            </p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canViewCredit"
              id="canViewCredit"
              label="View Credit"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              View Credit allows this user to view credit information specific to their account
              <br />
              <strong>Currently disabled/For future use</strong>
            </p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canViewHistory"
              id="canViewHistory"
              label="View History - This setting allows the user to view their order history"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>View History allows the user to view their order history</p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canViewPricing"
              id="canViewPricing"
              label="View Pricing"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              View Pricing allows this user to view product price lists that may be implemented
              currently or in the future
              <br />
              <strong>Currently disabled/For future use</strong>
            </p>
            <hr />
            <FormField
              type="radios"
              format="toggle"
              name="permissions.canViewInvoicing"
              id="canViewInvoicing"
              label="View Invoicing"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
            />
            <p>
              View Invoicing allows the user to request an invoice for a given order. The invoice
              will be delivered by email upon request
            </p>
            <hr />
            <SubmissionError error={submissionError} />
            <Button type="submit" disabled={isSubmitting}>
              Submit{isSubmitting && 'ting...'}
            </Button>
          </Form>
        )}
      </Formik>
      {canEditPassword && (
        <Modal isOpen={showPasswordModal} onClose={() => setShowPasswordModal(false)}>
          <Formik
            initialValues={{ password: '' }}
            validationSchema={passwordValidationSchema}
            onSubmit={(values, formik) =>
              onPasswordChange(values, formik, () => setShowPasswordModal(false))
            }
          >
            {({ isSubmitting }) => (
              <Form>
                <FormField type="password" name="password" id="password" label="Password" />
                <SubmissionError error={passwordChangeError} />
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
}

function AccountSelect() {
  const { error, loading, response: accounts } = useService(fetchAllAccounts);

  return (
    <>
      <FormField
        type="select"
        name="erp_account_id"
        id="erp_account_id"
        label="Account"
        disabled={error || loading}
        placeholder={loading ? 'Loading accounts...' : null}
        options={
          accounts
            ? accounts.map((acc) => ({
                label: `${acc.erp_account_name} (${acc.erp_account_id})`,
                value: acc.id
              }))
            : []
        }
      />
      {error && (
        <p className="heavy error">Failed to load accounts. Please try reloading the page.</p>
      )}
    </>
  );
}

function LocationSelect({ erp_account_id }) {
  const {
    error,
    loading,
    response: locations
  } = useService(fetchLocationsByAccount, erp_account_id);

  return (
    <>
      <FormField
        type="select"
        name="location_default"
        id="location_default"
        label="Location"
        disabled={!erp_account_id || error || loading}
        placeholder={loading ? 'Loading locations...' : null}
        options={
          locations
            ? locations.map((loc) => ({
                label: `${loc.description} (${loc.code})`,
                value: loc.id
              }))
            : []
        }
      />
      {error && (
        <p className="heavy error">Failed to load locations. Please try reloading the page.</p>
      )}
    </>
  );
}
